


import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';



import { IonCard, IonCardContent, IonIcon, IonHeader, IonToolbar, IonTitle, IonContent, IonText, setupIonicReact, IonButtons, IonMenuButton, IonItem, IonMenu,  IonMenuToggle } from '@ionic/react';
import {  chevronForward } from 'ionicons/icons';




setupIonicReact()



type props = {
    Width_: any,
}


export const Menu = ({ Width_ }: props) => {
    const navigate = useNavigate();

    return (

        <>
            <IonMenu contentId="main-content">
                <IonHeader mode='ios' style={{ "--background": "var(--color10)", boxShadow: "none" }}>
                    <IonToolbar mode="ios" style={{ "--background": "var(--color10)", boxShadow: "none" }}>
                        <IonTitle style={{ color: "white" }}>Menù</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent className="MenuContent" style={{ "--background": "none" }}>
                    <div id='scroll'></div>
                    <IonCard mode='ios' className='CardMenu' style={{ overflow: "auto", marginTop: "2rem", marginBottom: "4rem", background: "none", boxShadow: "none" }}>
                        <IonCardContent>
                            {
                                window.location.pathname !== '/'
                                    ?
                                    <IonMenuToggle><IonItem className='DivMenuItem' style={{ width: "100%", padding: "15px 0", fontWeight: "600" }} onClick={() => { window.location.assign('/') }}><Link style={{ textDecoration: "none", fontSize: "14px", color: "var(--color9)", fontWeight: "500" }} to={'/'}>Home</Link><IonIcon mode='ios' icon={chevronForward} className='ion-padding'></IonIcon></IonItem></IonMenuToggle>
                                    :
                                    ''
                            }
                            <IonMenuToggle><IonItem className='DivMenuItem' style={{ width: "100%", padding: "15px 0", fontWeight: "700" }} onClick={() => { window.location.assign('/Interpelli') }}><Link style={{ textDecoration: "none", fontSize: "14px", color: "var(--color9)", fontWeight: "700" }} onClick={() => { window.location.assign('/Interpelli') }} to={'/Interpelli'}>Interpelli 🚨</Link><IonIcon mode='ios' icon={chevronForward} className='ion-padding'></IonIcon></IonItem></IonMenuToggle>
                            <IonMenuToggle><IonItem className='DivMenuItem' style={{ width: "100%", padding: "15px 0", fontWeight: "700" }} onClick={() => { window.location.assign('/Candidature') }}><Link style={{ textDecoration: "none", fontSize: "14px", color: "var(--color9)", fontWeight: "700" }} onClick={() => { window.location.assign('/Candidature') }} to={'/Candidature'}>Candidature</Link><IonIcon mode='ios' icon={chevronForward} className='ion-padding'></IonIcon></IonItem></IonMenuToggle>
                            <IonMenuToggle><IonItem className='DivMenuItem' style={{ width: "100%", padding: "15px 0", fontWeight: "600" }} onClick={() => { window.location.assign('/Notizie') }}><Link style={{ textDecoration: "none", fontSize: "14px", color: "var(--color9)", fontWeight: "500" }} onClick={() => { window.location.assign('/Notizie') }} to={'/Contatti'}>Notizie</Link><IonIcon mode='ios' icon={chevronForward} className='ion-padding'></IonIcon></IonItem></IonMenuToggle>
                            <IonMenuToggle><IonItem className='DivMenuItem' style={{ width: "100%", padding: "15px 0", fontWeight: "600" }} onClick={() => { window.location.assign('/Contatti') }}><Link style={{ textDecoration: "none", fontSize: "14px", color: "var(--color9)", fontWeight: "500" }} onClick={() => { window.location.assign('/Contatti') }} to={'/Contatti'}>Contattaci</Link><IonIcon mode='ios' icon={chevronForward} className='ion-padding'></IonIcon></IonItem></IonMenuToggle>
                            <IonMenuToggle><IonItem className='DivMenuItem' style={{ width: "100%", padding: "15px 0", fontWeight: "600" }} onClick={() => { window.location.assign('/Prezzi') }}><Link style={{ textDecoration: "none", fontSize: "14px", color: "var(--color9)", fontWeight: "500" }} onClick={() => { window.location.assign('/Prezzi') }} to={'/Prezzi'}>Prezzi</Link><IonIcon mode='ios' icon={chevronForward} className='ion-padding'></IonIcon></IonItem></IonMenuToggle>
                        </IonCardContent>
                    </IonCard>
                </IonContent>
            </IonMenu>
        </>
    )
}

export const ComponentMenu = ({ Width_ }: props) => {
    const navigate = useNavigate();


    return (
        <div>
            {
                Width_ && Width_ > 900
                    ?
                    <IonCard mode='ios' style={{ margin: "0", borderRadius: "0", boxShadow: "none" }}>
                        {<div id="SovraIntestazione">
                          { /* <div id="ContattiMail">
                                <div style={{ color: "white" }}><a href='https://www.facebook.com/groups/738143620158761' target='_blank' style={{ fontWeight: "500", fontSize: "12px", color: "white", textDecoration: "none", display: "flex", alignItems: "center", gap: "0.5rem" }}><AsyncImage src={fblogo} style={{ width: "1rem", height: "1rem" }}></AsyncImage>Entra nella nostra Community</a></div>
                            </div>*/}

                        </div>}
                        <div id="Intestazione">
                            <div style={{ padding: "20px 0" }}>
                                <IonText className='Cursor' style={{ fontSize: "4.5vw", fontWeight: "600" }} onClick={() => { navigate('/') }}><span style={{ color: "white" }}>Easy</span>MAD.it</IonText>
                            </div>
                            <nav className="navbar">
                                <Link className="nav-item navLink1" to='/Notizie' style={{ color: "white", fontWeight: "600", fontSize: "13px" }}>Notizie</Link>
                                <Link className="nav-item navLink1" to='/Contatti' style={{ color: "white", fontWeight: "600", fontSize: "13px" }}>Contatti</Link>
                                <Link className="nav-item navLink1" to='/Prezzi' style={{ color: "white", fontWeight: "600", fontSize: "13px" }}>Prezzi</Link>
                                <Link className="nav-item navLink1" to='/Candidature' style={{ color: "white", fontWeight: "600", fontSize: "13px" }}>Candidature</Link>
                                <Link className="nav-item" id="InvioMADNav" to='/Interpelli' style={{ fontWeight: "700", fontSize: "14px" }}>Interpelli 🚨</Link>
                            </nav>
                        </div>
                    </IonCard>
                    :
                    <IonHeader class="ion-no-border" style={{ "--background": "var(--color10)", boxShadow: "none" }}>
                        <IonToolbar style={{ "--background": "var(--color10)", boxShadow: "none" }} >
                            <IonButtons slot='start' style={{ boxShadow: "none" }}>
                                <IonMenuButton style={{ color: "white", fontSize: "1rem", width: "fit-content", boxShadow: "none", fontWeight: "600" }} slot='start'>Menù</IonMenuButton>
                            </IonButtons>

                            <div className='Cursor' style={{ margin: "0 auto", width: "100%", textAlign: "center", fontSize: "18px", fontWeight: "700" }} onClick={() => { navigate('/') }}><span style={{ color: "white" }}>Easy</span>MAD.it</div>
                            {
                                window.location.pathname !== '/Interpelli'
                                    ?
                                    <IonButtons slot='end' >
                                        <IonText slot='start' style={{ color: "white", fontWeight: "500", fontSize: "14px", paddingRight: "20px" }} onClick={() => { window.location.assign('/Interpelli') }}>Interpelli 🚨</IonText>
                                    </IonButtons>
                                    :
                                    <IonButtons slot='end' >
                                        <IonText slot='start' style={{ color: "white",  fontSize: "14px", paddingRight: "20px", fontWeight: "600" }} onClick={() => { window.location.assign('/Contatti') }}>Contattaci</IonText>
                                    </IonButtons>
                            }


                        </IonToolbar>
                {  /*      <div style={{padding: "0 1rem 1rem 1rem", background: "var(--color10)"}}>
                            <div style={{ color: "white" }}><a href='https://www.facebook.com/groups/738143620158761' target='_blank' style={{ fontWeight: "500", fontSize: "12px", color: "white", textDecoration: "none", display: "flex", alignItems: "center", justifyContent: "center", gap: "0.5rem",width: "fit-content" , margin: "0 auto"}}><AsyncImage src={fblogo} style={{ width: "1rem", height: "1rem" }}></AsyncImage>Entra nella nostra Community</a></div>
                        </div>*/}
                        <div id='scroll'></div>

                    </IonHeader>
            }
        </div>
    )
}

